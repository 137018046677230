import { SUB_HEADING_TAB } from "../../components/UploadNotification/data";
import { Upload3DAction } from "../actions/playground3DuploadActions";

const initialState = {
    uploadProgress: null,
    successFiles: null,
    failedFiles: null,
    getFilteredFiles: null,
    isVisible: true,
    scrollTopVisible: false,
    activeTab: SUB_HEADING_TAB[0],
    statusCount: {
        totalCount: 0,
        successCount: 0,
        failedCount: 0,
        cancelledCount: 0,
    },
    uploadType: null,
    successFileUrls: null,
};

const upload3DReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const updateStateActions = [
        Upload3DAction.UpdateUploadProgress3D,
        Upload3DAction.UploadSuccess3D,
        Upload3DAction.UploadFailed3D,
        Upload3DAction.RefetchFiles3D,
        Upload3DAction.ClearUploadNotification3D,
        Upload3DAction.CancelUpload3D,
        Upload3DAction.CancelAllUpload3D,
        Upload3DAction.UpdateStatusCount3D,
        Upload3DAction.UpdateScrollTopVisible,
        Upload3DAction.UpdateNotificationVisibilty3D,
        Upload3DAction.RetryStarted3D,
        Upload3DAction.RetryAllUploadStarted3D,
    ];
    if (updateStateActions.includes(type)) {
        return {
            ...state,
            ...payload,
        };
    }
    switch (type) {
        case Upload3DAction.SetUploadNotiTab3D:
            return {
                ...state,
                ...{ activeTab: payload },
            };

        case Upload3DAction.UpdateSuccess3dFiles:
            return {
                ...state,
                ...{ success3dFiles: payload },
            };
        case Upload3DAction.SetFileUploadPath:
            return {
                ...state,
                ...{ fileUploadPath: payload },
            };
        case Upload3DAction.SetUploadType:
            return {
                ...state,
                ...{ uploadType: payload },
            };
        case Upload3DAction.UpdateSuccessFileURLs:
            return {
                ...state,
                ...{ successFileUrls: payload },
            };
        default:
            return state;
    }
};

export default upload3DReducer;
