export const Upload3DAction = {
    UpdateUploadProgress3D: "UPDATE_UPLOAD_PROGRESS_3D",
    UploadSuccess3D: "UPLOAD_SUCCESS_3D",
    UploadFailed3D: "UPLOAD_FAILED_3D",
    RefetchFiles3D: "REFETCH_FILES_3D",
    ClearUploadNotification3D: "CLEAN_UPLOAD_NOTIFICATION_3D",
    FilterFiles3D: "GET_FILTERED_FILES_3D",
    CancelUpload3D: "CANCEL_UPLOAD_3D",
    CancelAllUpload3D: "CANCEL_ALL_UPLOAD_3D",
    UpdateStatusCount3D: "UPDATE_STATUS_COUNT_3D",
    UpdateScrollTopVisible3D: "UPDATE_SCROLL_TOP_VISIBLE_3D",
    retryAllFailedUploads3D: "RETRY_ALL_FAILED_UPLOADS_3D",
    SetUploadNotiTab3D: "SET_UPLOAD_NOTI_TAB_3D",
    RetryStarted3D: "RETRY_STARTED_3D",
    RetryAllUploadStarted3D: "RETRY_ALL_UPLOAD_STARTED_3D",
    UpdateNotificationVisibilty3D: "UPDATE_NOTIFICATION_VISIBILITY_3D",
    SetFileUploadPath: "SET_FILE_UPLOAD_PATH",
    SetUploadType: "SET_UPLOAD_TYPE",
    UpdateSuccessFileURLs: "UPDATE_SUCCESS_FILE_URLS",
};

export const updateUploadProgress3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.UpdateUploadProgress3D,
        payload: data,
    });
};

export const uploadSuccess3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.UploadSuccess3D,
        payload: data,
    });
};

export const uploadFailed3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.UploadFailed3D,
        payload: data,
    });
};

export const refetchFiles3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.RefetchFiles3D,
        payload: data,
    });
};

export const filterFiles3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.FilterFiles3D,
        payload: data,
    });
};

export const clearNotification3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.ClearUploadNotification3D,
        payload: data,
    });
};

export const uploadCancelled3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.CancelUpload3D,
        payload: data,
    });
};

export const allUploadCancelled3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.CancelAllUpload3D,
        payload: data,
    });
};

export const retryStarted3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.RetryStarted3D,
        payload: data,
    });
};

export const retryAllUploadStarted3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.RetryAllUploadStarted3D,
        payload: data,
    });
};

export const updateStatusCount3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.UpdateStatusCount3D,
        payload: data,
    });
};

export const updateScrollTopVisible3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.UpdateScrollTopVisible3D,
        payload: data,
    });
};

export const setUploadNotiTab3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.SetUploadNotiTab3D,
        payload: data,
    });
};

export const updateNotificationVisibilty3D = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.UpdateNotificationVisibilty3D,
        payload: data,
    });
};

export const setFileUploadPath = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.SetFileUploadPath,
        payload: data,
    });
};
export const setUploadType = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.SetUploadType,
        payload: data,
    });
};
export const updateSuccessFileURLs = (data, dispatch) => {
    dispatch({
        type: Upload3DAction.UPDATE_SUCCESS_FILE_URLS,
        payload: data,
    });
};
