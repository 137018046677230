import { Playground3dMouleActions } from "../actions/playground3dModuleActions";

const initialState = {
    success3dFileList: [],
    processsing3dFileList: [],
    failed3dFilesList: [],
};

const playground3dModuleReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Playground3dMouleActions.SetSuccess3dFileList:
            return {
                ...state,
                success3dFileList: payload,
            };
        case Playground3dMouleActions.SetProcesssing3dFileList:
            return {
                ...state,
                processsing3dFileList: payload,
            };
        case Playground3dMouleActions.SetFailure3dFileList:
            return {
                ...state,
                failed3dFilesList: payload,
            };
        default:
            return state;
    }
};

export default playground3dModuleReducer;
