export const Playground3dMouleActions = {
    SetSuccess3dFileList: "SET_SUCCESS_3D_FILE_LIST",
    SetProcesssing3dFileList: "SET_PROCESSING_3D_FILE_LIST",
    SetFailure3dFileList: "SET_FAILURE_3D_FILE_LIST",
};

export const setSuccess3dFileList = (data, dispatch) => {
    dispatch({ type: Playground3dMouleActions.SetSuccess3dFileList, payload: data });
};
export const setProcesssing3dFileList = (data, dispatch) => {
    dispatch({ type: Playground3dMouleActions.SetProcesssing3dFileList, payload: data });
};
export const setFailure3dFileList = (data, dispatch) => {
    dispatch({ type: Playground3dMouleActions.SetFailure3dFileList, payload: data });
};
