export const Model3dActions = {
    SetPosition: "SET_POSITION",
    SetRotation: "SET_ROTATION",
    SetScale: "SET_SCALE",
    SetDirectionLightPosition: "SET_DIRECTIONLIGHT_POSITION",
    SetDirectionLightIntensity: "SET_DIRECTIONLIGHT_INTENSITY",
    SetDirectionLightColor: "SET_DIRECTION_LIGHT_COLOR",
    SetDirectionLightShadowRadius: "SET_DIRECTION_LIGHT_SHADOW_RADIUS",
    SetEnvironmentFilesValue: "SET_ENVIRONMENT_FILES_VALUE",
    SetEnvironmentExposure: "SET_ENVIRONMENT_EXPOSURE",
    SetCanvasColor: "SET_CANVAS_COLOR",
    SetAmbientLightColor: "SET_AMBIENT_LIGHT_COLOR",
    ResetTransform: "RESET_TRANSFORM",
    ResetLighting: "RESET_LIGHTING",
    SetIsMobileExportPanelHidden: "SET_IS_MOBILE_EXPORT_PANEL_HIDDEN",
    SetModelUrl: "SET_MODEL_URL",
};

export const setPosition = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetPosition, payload: data });
};
export const setRotation = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetRotation, payload: data });
};
export const setScale = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetScale, payload: data });
};
export const setDirectionLightPosition = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetDirectionLightPosition, payload: data });
};
export const setDirectionLightIntensity = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetDirectionLightIntensity, payload: data });
};
export const setDirectionLightColor = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetDirectionLightColor, payload: data });
};
export const setCanvasColor = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetCanvasColor, payload: data });
};
export const setAmbientLightColor = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetAmbientLightColor, payload: data });
};
export const setDirectionLightShadowRadius = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetDirectionLightShadowRadius, payload: data });
};
export const setEnvironmentFilesValue = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetEnvironmentFilesValue, payload: data });
};
export const setEnvironmentExposure = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetEnvironmentExposure, payload: data });
};

export const resetTransform = (dispatch) => {
    const data = {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
    };
    dispatch({ type: Model3dActions.ResetTransform, payload: data });
};
export const resetLighting = (dispatch) => {
    const data = {
        position: { x: 0, y: 6, z: 0 },
        intensity: 1,
        color: "#ffffff",
        shadowRadius: 1,
    };
    dispatch({ type: Model3dActions.ResetLighting, payload: data });
};
export const setIsMobileExportPanelHidden = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetIsMobileExportPanelHidden, payload: data });
};
export const SetModelUrl = (data, dispatch) => {
    dispatch({ type: Model3dActions.SetModelUrl, payload: data });
};
